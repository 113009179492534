/* eslint-disable linebreak-style */
// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import ProductCardFirstPage from '../shared/ProductCardFirstPage';
import ProductCardSubcategory from '../shared/ProductCardSubcategory';


export default function BlockProducts(props) {
    const {
        title,
        layout,
        featuredProduct,
        products,
        subcategory
    } = props;

    let large;
    let smalls;

    if (featuredProduct) {
        large = (
            <div className="block-products__featured">
                <div className="block-products__featured-item">
                    <ProductCardFirstPage product={featuredProduct} />
                </div>
            </div>
        );
    }
    if (products.length > 0) {
        const productsList = products.slice(0, 9).map((product, index) => (
            <div key={index} className="block-products__list-item">
                {subcategory?
               
                <ProductCardSubcategory product={product} />
                :
                    <ProductCardFirstPage product={product} />
    }
            </div>
        ));

        smalls = (

                 <div className={`${subcategory? "block-products__list_sub" :"block-products__list " }`}>
                 {productsList}
                </div>
        );
    }

    return (
        <div className={`block block-products block-products--layout--${layout}`}>
            <div className="container">

                <div className="block-products__body">
                    {layout === 'large-first' && large}
                    {smalls}
                    {layout === 'large-last' && large}
                </div>
            </div>
        </div>
    );
}

BlockProducts.propTypes = {
    featuredProduct: PropTypes.object,
    products: PropTypes.array,
    layout: PropTypes.oneOf(['large-first', 'large-last']),
};

BlockProducts.defaultProps = {
    products: [],
    layout: 'large-first',
};
